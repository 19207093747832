import { useAuth } from '~/store/auth/index'

export default defineNuxtRouteMiddleware(async ( to ) => {
  const storeAuth = useAuth()
  const router = useRouter();

  try {
    console.log({ to: to.path })

    if (storeAuth.status === 'authenticated') {
    
      return 
    } 
    else {
      // if (to.path === '/login') {
      //   return
      // }
      router.push('/login')
    }
  } catch (error) {
    console.error('Error en el middleware de autenticación:', error)
  }
})